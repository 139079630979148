@media screen and (min-width: $breakpoint) {
    .layout-container {
        &.layout-drawer {
            .layout-topbar {
                .topbar-menubutton {
                    display: none;
                }
            }

            .layout-sidebar {
                height: 100%;
                top: 0;
                transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
                width: 5.25rem;
                z-index: 1100;

                .layout-menu-container {
                    overflow: hidden;
                }

                .sidebar-header {
                    display: flex;
                    justify-content: center;
                    gap: 1rem;
                    padding: 1.5rem 0rem;

                    .app-logo {
                        .app-logo-small {
                            display: inline;
                            order: 1;
                        }

                        .app-logo-normal {
                            display: none;
                            width: 0;
                            transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
                            transition-delay: 300ms;
                        }
                    }

                    .layout-sidebar-anchor {
                        display: none;
                        width: 1.25rem;
                        height: 1.25rem;
                        border-radius: 50%;
                        border: 2px solid var(--logo-color);
                        background-color: transparent;
                        transition: background-color var(--transition-duration), transform 0.3s;
                    }
                }

                .layout-menu {
                    transition: all .4s;

                    ul {
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        a {
                            padding: 0.75rem 2rem;
                            width: 1.25rem;
                            span {
                                opacity: 0;
                                white-space: nowrap;
                                transition: all .1s;
                            }

                            .layout-menuitem-icon {
                                font-size: 1.25rem;
                                width: 1.25rem;
                                transition: font-size 0.3s;
                            }

                            &.active-route {
                                .layout-menuitem-icon {
                                    color: #000; 
                                }
                            }
                        }
                        
                    }
                }
            }

            &.layout-dark,
            &.layout-dim {
                .layout-menu {
                    ul {
                        a {
                            &.active-route {
                                .layout-menuitem-icon {
                                    color: #FFF; // Branco para temas escuros e dim quando rota ativa
                                }
                            }
                        }
                    }
                }
            }
        }

        &.layout-drawer {
            .layout-content-wrapper {
                margin-left: 5.25rem;
                transition: margin-left 0.3s cubic-bezier(0, 0, 0.2, 1);
                overflow-x: hidden;
            }
        }

        &.layout-sidebar-active {
            .layout-sidebar {
                width: 18rem;

                .layout-menu-container {
                    overflow: auto;
                }

                .sidebar-header {
                    padding: 1.5rem 2rem;
                    justify-content: space-between;

                    .app-logo {
                        .app-logo-normal {
                            display: inline;
                            width: 100%;
                            transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
                            transition-delay: 300ms;
                        }

                        .app-logo-small {
                            display: none;
                        }
                    }

                    .layout-sidebar-anchor {
                        display: block;
                        animation: px-fadein 0.15s linear;
                    }
                }

                .layout-menu {
                    .layout-root-menuitem {
                        >.layout-menuitem-root-text {
                            padding: 1rem 2rem;
                            padding-right: 1.25rem;
                            opacity: 1;
                            white-space: nowrap;
                            transition: all .3s;

                            >.layout-menuitem-root-icon {
                                display: none;
                            }
                        }
                    }

                    ul {
                        a {
                            padding: 0.75rem 2rem;
                            padding-right: 1.25rem;
                            width: auto;
                            .layout-submenu-toggler {
                                display: block;
                            }

                            span {
                                opacity: 1;
                                white-space: nowrap;
                                transition: all .3s;
                            }
                        }
                    }
                }
            }
        }

        &.layout-sidebar-anchored {
            .sidebar-header {
                .layout-sidebar-anchor {
                    background-color: var(--logo-color) !important;
                }
            }

            .layout-content-wrapper {
                margin-left: 18rem;
            }
        }
    }
}


