/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system
@import '~owl.carousel/dist/assets/owl.carousel.min.css';
@import '~owl.carousel/dist/assets/owl.theme.default.min.css';

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

// /* PrimeNG */
// @import "../node_modules/primeng/resources/primeng.min.css";
// @import "../node_modules/primeflex/primeflex.scss";
// @import "../node_modules/primeicons/primeicons.css";

// /* Quill Text Editor for p-editor */
// @import "../node_modules/quill/dist/quill.core.css";
// @import "../node_modules/quill/dist/quill.snow.css";


/* PrimeNG */
/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
// @import "../node_modules/quill/dist/quill.core.css";
// @import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

:host {
    all: unset;
    display: block;

    * {
        all: unset;
        font: inherit;
    }
}


.layout-menuitem {
    padding-left: 28px;
    padding-right: 28px;
    margin: 0;
}

.dropdown-fixed-width {
    width: 100%;
}

.p-dropdown {
    width: 100%;
}

.ss-g-menuP {
    // margin-bottom: 25px;
}

.p-tabview .p-tabview-nav,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: #ffffff00;
}


///////////////
.split-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.ss-g-split-button-custom .p-splitbutton-defaultbutton {
    max-width: 5rem !important;
}

.ss-g-valor-container {
    display: inline-block;
    width: 100px;
    text-align: left;
}


.ss-g-buttonMenu:focus {
    box-shadow: none !important;
}

.ss-g-buttonMenu:enabled:hover {
    background: transparent !important;
}

.ss-g-valor-e-botoes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.ss-g-valor-container {
    flex-grow: 1;
    text-align: left;
}

.ss-g-progressSpinnerMenu .p-progress-spinner {
    width: 30px;
    height: 30px;
}

.p-tabview-nav-content .p-tabview-nav li:hover {
    background-color: none;
}

.p-tabview .p-tabview-nav li:hover {
    background-color: transparent !important;
}

.ss-g-buttonExtratoMenu {
    right: -10px !important;
    top: 7px !important;
}

.ss-g-buttonExtratoMenu:enabled:active {
    background: transparent !important;
    border: none !important;
}

.ss-g-buttonExtratoMenu:hover,
.ss-g-buttonExtratoMenu:focus {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none;
}

.ss-g-buttonExtratoMenu:focus-visible {
    outline: none;
    box-shadow: none;
}

.ss-g-buttonExtratoMenu {
    transition: none !important;
}

.p-button-success .p-menu {
    z-index: 1000 !important;
}

.p-tabview .p-tabview-panels {
    background: #ffffff00 !important;
}

.split-button-custom2 .p-splitbutton .p-splitbutton-defaultbutton,
.split-button-custom2 .p-splitbutton .p-splitbutton-menubutton {
    background-color: transparent !important;
    border: none !important;
}

.p-tabview .p-tabview-nav {
    border: none !important;
}

.ss-g-panelMenu .p-tabview-panel {
    margin-bottom: 8.75px !important;
}

.ss-g-backColorMenuWallet {
    --text-color: #fff;

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        color: var(--text-color);
    }
}

.ss-g-backColorMenuWallet .p-tabview .p-tabview-nav li .p-tabview-nav-link:hover {
    --menuitem-text-color: var(--text-color);
}

.table-tag-yellow span {
    background-color: yellow !important;
}

.table-tag-green span {
    background-color: green !important;
}

.table-tag-red span {
    background-color: red !important;
}

.table-tag-darkgray span {
    background-color: darkgray !important;
}

.table-tag-orange span {
    background-color: orange !important;
}

.centered-content {
    text-align: center !important;
}

.calendar-grid .p-calendar {
    min-width: 100% !important;
}

@media (min-width: 576px) {
    .ss-g-chat {
        max-width: 32.5rem;
    }

    .ss-g-custom-sidebar-width .p-sidebar-right {
        width: 35rem !important;
    }
}

@media (max-width: 576px) {
    .ss-g-chat {
        max-width: 100vw !important;
    }

    .ss-g-custom-sidebar-width .p-sidebar-right {
        width: 100vw !important;
    }
}

.p-multiselect .p-multiselect-label {
    flex-wrap: wrap;
    display: flex;
}

.ss-g-inputM {
    display: grid !important;
}

.ng-invalid {
    border-color: #e24c4c;
}

.p-inputnumber {
    display: inline-flex;
    width: 100% !important;
}

.input-wrapper-vencimento .p-inputnumber .p-inputnumber-input {
    border: none !important;
    border-bottom: 2px solid black !important;
    border-radius: 0 !important;
}

.p-dialog-mask {
    z-index: 1500 !important;
}

.p-confirm-dialog {
    z-index: 5103 !important;

}

.ss-g-container-qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.ss-g-button-container-qr-code {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.left-button {
    align-self: flex-start;
}

.right-button {
    align-self: flex-end;
}

/////////////
.ss-g-custom-inputgroup .custom-input,
.ss-g-custom-inputgroup .custom-button {
    border: 1px dashed #d0d0d0;
}

.ss-g-custom-inputgroup .custom-button {
    background-color: transparent;
    color: #007ad9;
    border-radius: 0;
}

.ss-g-custom-inputgroup .custom-input {
    border-right: none;
}

.ss-g-custom-inputgroup .p-inputtext {
    border-right: none;
}

/// ////////////
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ss-g-remove-coupon {
    text-align: left;
    cursor: pointer;
}

.ss-g-register-link {
    text-align: right;
}

.ss-g-product-label {
    flex-grow: 0;
    flex-shrink: 1;
    max-width: 160px;
    white-space: normal;
    overflow-wrap: break-word;
    word-break: break-word;
    padding: 0.5rem;
}

.label-spacing {
    margin-right: 5px;
}

.p-dropdown-item {
    overflow: visible !important;
}


.dialogAguardando .p-dialog {
    z-index: 1102 !important;
}


.ss-g-custom-sidebar-width .p-sidebar-content {
    overflow-y: auto;
    overflow-x: hidden;
}

.p-dropdown-panel {
    z-index: 9999;
    max-width: 100%;
}

.disabled-item {
    color: grey;
    pointer-events: none;
}

.p-multiselect .p-multiselect-label .p-multiselect-token .p-multiselect-token-label {
    white-space: normal;
    line-height: 1.5;
    overflow-wrap: break-word;
}

.p-multiselect .p-multiselect-items .p-multiselect-item,
.p-multiselect .p-multiselect-items .p-highlight {
    white-space: normal;
    line-height: 1.5;
    overflow-wrap: break-word;
}

.p-multiselect-panel {
    z-index: 9999 !important;
    /* Valor alto para garantir que fique por cima de outros elementos */
}

@media (max-width: 768px) {
    .product-flex-row {
        flex-direction: column;
    }
}

.ss-g-imgCenter {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.delivery-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.p-fileupload .p-fileupload-buttonbar {
    display: none;
}

.pointer-cursor {
    cursor: pointer;
}

.ui-dialog {
    z-index: 12103 !important;
}

.p-autocomplete-panel {
    z-index: 12104 !important;
    /* Valor mais alto que o z-index do p-dialog */
}

.p-dialog-mask.p-component-overlay {
    z-index: 99991 !important;
}

.p-fileupload-files {
    display: none !important;
}

.position-relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.ss-g-globalClassLabel {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

/// ////////////
.label-with-icon label {
    display: flex;
    align-items: center;
}


.p-multiselect-token-label {
    white-space: normal;
    max-width: 250px;
    height: auto;
    line-height: normal;
    overflow: hidden;
}

.p-toast .p-toast-message {
    z-index: 9999 !important;
}

.break-word {
    word-break: break-all;
}

.chip-remove-icon {
    width: 1.5rem;
    height: 1.5rem;

    i {
        font-size: 9px;
    }
}

:host ::ng-deep {
    .p-fileupload {
        padding: 0;

        .p-fileupload-buttonbar {
            display: none;
        }

        .p-fileupload-content {
            border: 0 none;
        }

        .p-fileupload-row {
            display: none;
        }

        .p-progressbar {
            display: none;
        }
    }
}

.p-fileupload-content.p-fileupload-highlight {
    border-color: var(--primary-color);
    border-width: 2px;
    border-style: dashed;
    background-color: var(--primary-100);
}


.p-button.p-fileupload-choose input[type='file'] {
    display: none;
}

.p-fileupload-choose.p-fileupload-choose-selected input[type='file'] {
    display: none;
}

@media (min-width: 576px) {
    .sm-mb-2 {
        margin-bottom: 0.5rem !important;
    }

}

@media (max-width: 767px) {
    .p-overlaypanel {
        width: 90% !important;
        margin-left: 5% !important;
        margin-right: 5% !important;
    }

    .p-multiselect-token-label {
        max-width: 200px;
    }
}

@media (max-width: 768px) {
    .clear-button {
        display: block !important;
        margin-left: 0 !important;
        width: 100% !important;
    }

    .ss-g-right-section {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .ss-g-right-section>.b1 {
        flex-grow: 2;
    }

    .p-calendar {
        min-width: 100% !important;
    }
}

.controls-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

p-calendar {
    flex-grow: 1;
}

p-splitButton {
    flex-shrink: 0;
}

@media (max-width: 576px) {
    .calendar-grid {
        display: grid !important;
    }
}

.client-column {
    white-space: nowrap;
    overflow: visible;
    max-width: 0;
}

.centered-content-sales {
    text-align: left !important;
}

/////////////////
.caption-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.left-section {
    display: flex;
    align-items: center;
}

.clear-button {
    margin-left: 10px;
}

@media (max-width: 768px) {
    .caption-container {
        flex-direction: column;
    }

    .left-section,
    .clear-button,
    .left-section>*,
    .caption-container>* {
        width: 100% !important;
    }

    .left-section {
        flex-direction: column;
    }

    .clear-button {
        margin-left: 0 !important;
    }

    .ss-g-right-section {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
    }

    .ss-g-right-section>.b1 {
        flex-grow: 2;
    }
}

@media (max-width: 576px) {

    .calendar-grid {
        width: 100%;
    }

    .clear-button[_ngcontent-ng-c4100369507] {
        margin-left: 0 !important;
    }

    .p-calendar {
        width: 100% !important;
    }
}

/////////////////
.page-title h1 {
    color: #8c8c8c;
    text-align: left;
    margin-top: 0;
    padding-top: 0px;
    padding-left: 0px;
    font-size: 16px;
    font-weight: normal;
}

.ss-g-sidebarVendas {
    display: grid;
}

.ss-g-custom-divider {
    width: 100%;
    height: 1px;
    background-color: #ccc;
}

.focus-effect {
    border: 2px solid red;
    background-color: #ffdddd;
    color: red;
}

.p-calendar {
    width: 100% !important;
}


.sidebar-footer {
    text-align: right;
}

.disabled-item {
    color: grey;
    pointer-events: none;
}


.ss-g-labels-container {
    display: flex;
    flex-direction: column;
}

.flex-column {
    flex-direction: column;
}

/// css default


.ss-g-selectButton-50 .p-buttonset .p-button:first-of-type {
    width: 50%;
}

.ss-g-selectButton-50 .p-buttonset .p-button:last-of-type {
    width: 50%;
}

.ss-g-tabMenuBorder-20 .p-tabmenu-nav-content {
    border-radius: 20px;
}

.ss-g-positionSpeedDialUp-relative {
    position: absolute !important;
    bottom: 15vh;
    right: 1rem !important;
}

.ss-g-positionBarControl {
    position: absolute !important;
    bottom: 0;
}

.ss-g-menu-inf {
    top: 84%;
}

.ss-g-menu-inf2 {
    bottom: 5%;
}

.ss-g-menu-inf>.flex {
    display: flex !important;
    justify-content: space-between !important;
    /* Espaço entre os itens será igual */
    align-items: center !important;
    width: 100% !important;
    padding: 0 1.5rem !important;
    /* Ajuste este valor para garantir o espaço desejado nas extremidades */
}

.ss-g-menu-inf2>.flex {
    display: flex !important;
    justify-content: space-between !important;
    /* Espaço entre os itens será igual */
    align-items: center !important;
    width: 100% !important;
    padding: 0 5.5rem !important;
    /* Ajuste este valor para garantir o espaço desejado nas extremidades */
}

.ss-g-menu-inf .flex>div {
    display: flex !important;
    justify-content: center !important;
    /* Centraliza o conteúdo do botão */
    flex: 1 !important;
    /* Distribui o espaço igualmente */
    text-align: center !important;
}

.ss-g-menu-inf2 .flex>div {
    display: flex !important;
    justify-content: center !important;
    /* Centraliza o conteúdo do botão */
    flex: 1 !important;
    /* Distribui o espaço igualmente */
    text-align: center !important;
}

.ss-g-menu-inf .flex>div:not(:first-child) {
    margin-left: 1rem !important;
    /* Ajuste este valor para definir o espaço entre os botões */
}

.ss-g-menu-inf2 .flex>div:not(:first-child) {
    margin-left: 1rem !important;
    /* Ajuste este valor para definir o espaço entre os botões */
}

.ss-g-menu-inf .flex>div:not(:last-child) {
    margin-right: 0rem !important;
    /* Ajuste este valor para definir o espaço entre os botões */
}

.ss-g-menu-inf2 .flex>div:not(:last-child) {
    margin-right: 1rem !important;
    /* Ajuste este valor para definir o espaço entre os botões */
}



.ss-g-positionSpeedDialUp-relative .p-speeddial-mask-visible {
    opacity: 0 !important;
}

.ss-g-positionSpeedDialUp-relative .p-speeddial-direction-left {
    position: relative;
}

.ss-g-postionProductsDestaque {}

.ss-g-positionSpeedDialUp-relative .p-speeddial-button.p-button.p-button-icon-only {
    width: 3rem !important;
    height: 3rem !important;
}


.p-tabmenu .p-tabmenu-nav-btn.p-link {
    border-radius: 12px !important;
    box-shadow: none;
}

@import 'swiper/css';
@import 'swiper/css/navigation';
@import 'swiper/css/pagination';
//@import "~video.js/dist/video-js.css";

:host ::ng-deep .p-menu.p-menu-overlay {
    z-index: 15000 !important;
}

.p-menu.p-menu-overlay {
    z-index: 15000 !important;
}

@media (max-width: 374px) {
    :host ::ng-deep .p-menu.p-menu-overlay {
        z-index: 15000 !important;
        transform: scale(0.75) !important;
        transform-origin: top left;
        transition: none !important;
    }

    .p-menu.p-menu-overlay {
        z-index: 15000 !important;
        transform: scale(0.75) !important;
        transform-origin: top left;
        transition: none !important;
    }
}

// layout chat 
.ss-g-input-group-chat {
    position: relative;
    width: calc(100% - 2rem);
    /* Ajusta a largura para considerar o padding */
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    /* Espaçamento à esquerda */
    padding-right: 1rem;
    /* Espaçamento à direita */
    display: flex;
    /* Utiliza flex para alinhar os itens verticalmente */
    align-items: center;
    /* Alinha os itens verticalmente ao centro */
    gap: 0.5rem;
    /* Define o espaço entre os itens */
}

.ss-g-input-text-chat {
    flex-grow: 1;
    /* O input cresce para ocupar o espaço disponível */
    margin-right: 0px;
    /* Desloca os botões para a direita, sob o input */
    width: 100%;
    height: 100%;
}

.ss-g-button-container-chat {
    position: absolute;
    right: 1rem;
    /* Posiciona os botões levando em conta o padding */
    top: 50%;
    transform: translateY(-50%);
    /* Centraliza os botões verticalmente */
    display: flex;
    gap: 0.5rem;
}

:host ::ng-deep .p-picklist-item .p-ripple {
    display: none !important;
}


.ss-g-custom-z-index-emojis {
    z-index: 12001 !important;
}

.ss-g-cursor-pointer {
    cursor: pointer;
}


:host ::ng-deep .p-component-overlay {
    z-index: 997 !important;
}

:host ::ng-deep div.p-component-overlay.p-sidebar-mask.p-component-overlay-enter {
    z-index: 997 !important;
}

:root ::ng-deep div.p-component-overlay.p-sidebar-mask.p-component-overlay-enter {
    z-index: 997 !important;
}

.body-fixed {
    position: fixed;
    width: 100%;
}

:host ::ng-deep .ss-g-hover-border {
    background-color: var(--surface-50) !important;
    border-radius: 12px !important;
}

:host ::ng-deep .ss-g-hover-border:hover {
    background-color: var(--surface-ground) !important;
}

:host ::ng-deep .ss-g-hover-border {
    border: 2px dotted #888 !important;
    border-radius: 12px !important;
    padding: 28px !important;
}

@media (max-width: 768px) {
    :host ::ng-deep .ss-g-hover-border {
        min-height: 160px !important;
    }
}


.custom-divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.divider-line {
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
    /* A cor pode ser ajustada conforme necessário */
}

.divider-text {
    padding: 0 10px;
    background-color: #fff;
    /* Cor do fundo para cobrir a linha onde o texto aparece */
    color: #666;
    /* Cor do texto */
    font-weight: bold;
}


.full-screen-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Faz o contêiner ter a altura total da tela */
    width: 100vw;
    /* Faz o contêiner ter a largura total da tela */
}

.create-account-form {
    border: 1px solid #ccc;
    /* Apenas para visualização, ajuste conforme necessário */
    border-radius: 8px;
    padding: 2rem;
    width: auto;
    /* Ajuste a largura conforme necessário */
    max-width: 500px;
    /* Limita a largura máxima do formulário */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Sombra opcional para melhor visualização */
}


// classes do video-js para ajuste no vídeo
.video-js .vjs-tech {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}

.ss-g-video-container-inner-landscape {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
}

.ss-g-video-container-inner-landscape {
    position: relative;
}

.ss-g-video-container-inner-landscape .ss-l-rpf-bar {
    display: flex;
}

.ss-g-btn-screen-custom {
    padding-left: 4rem;
    padding-right: 4rem;
    height: 3rem;
    justify-content: center;
    max-height: 3rem;
}

.ss-g-btns-menu-sup {
    border-radius: 50% !important;
    max-width: 2rem;
    padding: 1rem;
    height: 2rem;
    justify-content: center;
    color: white;
    font-size: large;
    background-color: #FFFFFF00;
    z-index: 3;
}

.ss-g-btn-plus-links {
    background-color: transparent;
}

.ss-g-muteImg {
    width: 30vh;
    height: 20vh;
    position: absolute;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    justify-content: center;
    background: radial-gradient(#00081822 30%, #7c7c7c44);
    backdrop-filter: blur(5px);
    overflow: hidden;
    transition: transform .25s;
    border-radius: 10px;
}

.ss-g-muteImg i {
    font-size: 50px;
}

.ss-g-custom-bg {
    background-color: rgba(43, 51, 63, 0.7);
}

.ss-g-custom-bg:hover {
    transform: scale(1.05)
}

.ss-g-links2 {
    width: calc(20% - 10px);
    /* Subtrai a margem total horizontal (5px à esquerda + 5px à direita) */
    margin: 5px;
    /* Margem para espaço entre os itens */
    text-align: center;
    /* Centraliza o texto dentro do item */
}

.ss-g-links2:hover {
    transform: scale(1.05)
}

.ss-g-custom-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* Centraliza os itens na linha */
    align-items: center;
    /* Centraliza os itens verticalmente */
    padding: 5px;
    /* Espaço dentro do contêiner */
}

.ss-g-maxheight-links-adds {
    height: 40px;
    width: 40px;
}

/* Imagem dentro dos itens */
.ss-g-maxheight-links-adds {
    max-width: 100%;
    /* Faz a imagem ocupar toda a largura do item */
    border-radius: 50%;
    /* Circular se for o caso */
}

:host ::ng-deep .p-dialog {
    max-width: 50%;
}

@media (max-width: 675px) {
    :host ::ng-deep .p-dialog {
        max-width: 90%;
    }
}


.ss-g-btn-screen-custom {
    transition: background-color 0.3s ease, color 0.3s ease;
    /* Adiciona uma transição suave */
}

.ss-g-btn-screen-custom:hover {
    filter: brightness(1.6);
    /* Deixa a cor 20% mais clara */
}


.ss-g-imgNotFound {
    width: 50px !important;
}

.ss-g-centered-container {
    display: flex;
    flex-direction: column;
    /* Organiza os itens em uma coluna */
    justify-content: center;
    /* Centraliza horizontalmente */
    align-items: center;
    /* Centraliza os itens horizontalmente no centro */
    height: 100%;
    width: 100%;
}

.ss-g-text-below-image {
    font-size: 16px;
    /* Tamanho da fonte */
    text-align: center;
    /* Centraliza o texto horizontalmente */
    margin-top: 10px;
    /* Espaço entre a imagem e o texto */
}

.link-container {
    margin: 0;
    padding: 0;
    border-radius: 5px;
}

.link-card {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem;
    height: 4.5rem;
    border-radius: 5px;
    background: radial-gradient(#fff2 30%, #403d7944);
    backdrop-filter: blur(5px);
    overflow: hidden;
    transition: transform .25s;
    backdrop-filter: blur(5px);
}



.link-name {
    flex-grow: 1;
    text-align: left;
    margin: 0 0rem;
    font-size: 1.25rem;
    font-weight: 700;
    height: 100%;
    padding-left: 1rem;
}

.link-icon {
    font-size: 2rem;
    cursor: pointer;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    align-content: center;
    padding-right: .75rem;
}

.ss-g-btns-menu-right {
    display: flex;
    align-items: center;
    border: none;
    padding: 1.75rem;
    border-radius: 50% !important;
    max-width: 30px !important;
    padding: 1.5rem !important;
    height: 30px !important;
    justify-content: center;
    color: #fff !important;
    font-size: large !important;
    background: radial-gradient(#ffffff42 30%, #7875aa44) !important;
}

.ss-g-btns-menu-right i {
    font-size: 22px; // Ajuste conforme necessário para caber dentro do botão
    line-height: 1;
}


.ss-g-btns-menu-right2 {
    display: flex;
    align-items: center;
    border: none;
    padding: 1.75rem;
    border-radius: 50%;
    max-width: 30px;
    padding: 1.20rem;
    height: 30px;
    justify-content: center;
    color: #fff;
    font-size: large;
    background: radial-gradient(#ffffff42 30%, #7875aa44);
}

.ss-g-btns-menu-right2 i {
    font-size: 20px; // Ajuste conforme necessário para caber dentro do botão
    line-height: 1;
}

.ss-g-custom-bg:hover {
    transform: scale(1.05)
}

.ss-g-links-shorts:hover {
    transform: scale(1.05)
}



.ss-g-heartBgColor {
    color: red;
}


.ss-g-whatsapp-btn {
    background-color: #25D366 !important;
    /* Cor de fundo verde WhatsApp */
    color: white !important;
    /* Cor do texto */
    border-radius: 50% !important;
    /* Torna o botão circular */
    width: 35px !important;
    /* Define a largura do botão */
    height: 35px !important;
    /* Define a altura do botão */
    padding: 1.50rem !important;
    /* Ajuste o padding conforme necessário */
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
    /* Sombra leve para dar profundidade */
    font-size: 22px;
    /* Tamanho do ícone */
    border: none;
    /* Remover borda padrão */
    cursor: pointer;
    /* Cursor de clique */
    display: flex;
    /* Utiliza flexbox para alinhar o ícone */
    justify-content: center;
    /* Centraliza o ícone horizontalmente */
    align-items: center;
    /* Centraliza o ícone verticalmente */
}

.ss-g-whatsapp-btn:hover {
    background-color: #20C659;
    /* Cor de fundo um pouco mais clara ao passar o mouse */
}

:host ::ng-deep .ss-g-whatsapp-btn .pi-whatsapp {
    font-size: 22px;
    /* Cor de fundo um pouco mais clara ao passar o mouse */
}

.ss-l-links-shorts-style {
    border-radius: 50%;
    width: 3rem;
    height: 40px;
    width: 40px;
    overflow: hidden;
}

.ss-g-links-shorts {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Centraliza o conteúdo da card verticalmente e horizontalmente */
    justify-content: center;
    width: 100%;
    /* Opcional: Ajuste conforme necessário para limitar a largura total */
    position: relative;

    width: 100%;
    height: 100%;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    padding: 0 !important;
}

.ss-g-links-shorts img {
    width: 100%;
    height: 100%;
    max-width: 47px;
    max-height: 47px;
    object-fit: cover;
}

// .link-name, .link-icon{
//     filter: blur(5px);
// }

.link-image,
.link-icon {
    flex: 0 0 auto;
    /* Não crescem ou encolhem além do conteúdo */
}

.link-card:hover {
    transform: scale(1.02)
}

.link-image {
    border-bottom-left-radius: 5px !important;
    border-top-left-radius: 5px !important;
    height: 100%;
    width: 4.5rem;
    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.link-image .image-style {
    height: 100%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

// .ss-g-fade-out-swiper{
//     mask-image: linear-gradient(to left, rgb(0 0 0 / 18%), rgb(0 0 0) 5%, rgb(0 0 0) 100%);
//     backdrop-filter: blur(5px);
// }
.ss-g-progress-container-preview {
    // position: absolute;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    transition: height 0.2s, transform 0.2s;
    height: 8px;
    overflow: visible;
    cursor: pointer;
    transition: height 0.2s ease-in-out;
    z-index: 30;
}

.ss-g-progress-container-expanded {
    height: 12px !important;
}

@media (hover: hover) {
    .ss-g-progress-container-preview:hover {
        // height: 12px;
        transform: translateY(-2px);
    }
}

@media (hover: none) and (pointer: coarse) {
    .ss-g-progress-container-preview.touch-active {
        transform: translateY(-2px);
        transition: transform 0.2s ease-in-out;
    }
}

// Loja
.ss-l-progressBarTime {
    align-items: center;
    position: absolute;
    top: var(--progress-bar-top-time);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    padding: 10px;
    border-radius: 25px;
    white-space: nowrap;
    font-size: clamp(1rem, 2vw, 2.5rem);
    display: none !important;
}

.visible {
    display: block !important;
}

// Studio 
.ss-l-progressBarTime2 {
    align-items: center;
    position: absolute;
    //top: 690px;
    top: var(--progress-bar-top-time);
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    padding: 10px;
    border-radius: 25px;
    z-index: 3;
    visibility: hidden;
}

.hiddenBar {
    visibility: hidden;
}

.visible2 {
    visibility: visible;
}

.ss-g-progreesBarStudio {
    //top: 785px;
    right: 0px;
    position: absolute;
}


.unselectable {
    user-select: none;
    /* Padrão para a maioria dos navegadores */
    -webkit-user-select: none;
    /* Versão específica para Chrome e Safari */
    -moz-user-select: none;
    /* Versão específica para Firefox */
    -ms-user-select: none;
    /* Versão específica para Internet Explorer */
}


.alert-child {
    display: flex;
    align-items: center;
    /* Assegura alinhamento vertical central */
    justify-content: space-between;
    /* Mantém espaço entre os itens */
    border-radius: 52px !important;
}

.ss-g-postionAlertsGrid {
    position: relative;
    top: 2px;
}

.ss-g-postionAlertsGrid .ss-g-sino {
    position: relative;
    top: 3px;
    left: 7px;
}

.ss-g-bellAlertsFix {
    margin-left: 1rem;
    font-size: 22px;
    line-height: 1;
}

.ss-g-sino {
    color: white;
}

.s-g-menuLoja {
    z-index: 9999 !important;
    /* Elevar a prioridade do menu */
    position: fixed !important;
    /* Tornar o menu fixo no layout */
}

.lottie-container {
    pointer-events: none !important;
    /* Desativa a interatividade do Lottie */
}

:host ::ng-deep .ss-g-speedDialPosition .p-speeddial-button {
    position: absolute !important;
    z-index: 1002;
}

.ss-g-speedDialPosition .p-speeddial-button {
    position: absolute !important;
    z-index: 11002;
}


:host ::ng-deep .ss-g-sidebarChat {
    width: 100%;
}

@media (min-width: 992px) {

    /* Para telas de 992px de largura ou mais (considerando desktop) */
    :host ::ng-deep .ss-g-sidebarChat {
        display: block;
        /* Define display como block para permitir controle de largura */
        width: 50%;
        /* Define 50% da largura */
        left: 50%;
        /* Move o sidebar para o centro horizontal */
        transform: translateX(-50%);
        /* Ajusta a posição para o centro exato */
    }
}

/* Garante que a sidebar esteja na parte inferior */
:host ::ng-deep .ss-g-sidebarChat {
    position: fixed;
    /* Para fixar a sidebar na tela */
    bottom: 0;
    /* Fixa na parte inferior */
}

:host ::ng-deep .dialog-content-wrapper {
    border: 2px solid red;
    /* Teste temporário */
    background-color: rgba(255, 0, 0, 0.2);
    /* Teste temporário */
    animation: 1.6s ease-out 0s infinite normal none running slicksellPulse !important;
}


@keyframes slicksellPulse {
    0% {
        box-shadow: 0 0 0 0px rgba(128, 128, 128, 0.4), 0 0 0 0px rgba(128, 128, 128, 0.2);
    }

    25% {
        box-shadow: 0 0 0 0px rgba(128, 128, 128, 0.4), 0 0 0 0px rgba(128, 128, 128, 0.2);
    }

    100% {
        box-shadow: 0 0 0 16px rgba(128, 128, 128, 0), 0 0 0 24px rgba(128, 128, 128, 0);
    }
}



:host ::ng-deep .layout-container.layout-drawer .layout-sidebar {
    z-index: 1100 !important;
}